import { EcommerceItem } from "@models/GoogleAnalytics/EcommerceEvents.model"
import { OrderConfirmationDataModel } from "@models/OrderConfirmation"
import { CurrentPlateDesign, PlateCategoryMetaInfo, TransactionType } from "@models/ProductModels"
import { LOCALSTORAGE_PAYMENT } from "@redux/localStorage";
import { getPaymentMetodToLocalStorage } from "@redux/localStorage/basket";
import { GAService } from "@services/GAService"
import Cookies from "js-cookie";

// afterpay purchase GA4 mapping
export const mapOrderConfirmationToGAEcommerceEventModel = (event: string, isDealerOrder: boolean, orderConfirmationData: any) => {
    let ecommerceItems: EcommerceItem[] = []

    orderConfirmationData.EcommerceEvent.EcommerceEventItems.map((item: any, index: number) => {
        let itemCategory2 = ''
        switch ((item.transactionType.split(' ')[0]).toLowerCase()) {
            case "new":
                itemCategory2 = "New Design";
                break;
            case "redesign":
            case "upgrade":
                itemCategory2 = "Redesign";
                break;
        }
        ecommerceItems.push({
            item_id: item.plateStyleCode,
            item_name: item.plateDesignName,
            affiliation: "",
            coupon: item.discount && orderConfirmationData.Coupon ? orderConfirmationData.Coupon : "",
            currency: "NZD",
            discount: item.discount ? item.discount : 0,
            index: index,
            item_brand: "Kiwiplates",
            item_category: item.plateGroupSetName,
            item_category2: itemCategory2,
            item_category3: isDealerOrder ? "Dealer" : "Individual",
            item_category4: item.plateGroupName,
            item_category5: "",
            item_list_id: "",
            item_list_name: "",
            item_variant: item.colorName,
            location_id: "",
            price: item.price,
            quantity: 1
        })
    })
    return {
        event: event,
        ecommerce: {
            transaction_id: orderConfirmationData.EcommerceEvent.id,
            affiliation: '',
            value: orderConfirmationData.EcommerceEvent.Price,
            tax: orderConfirmationData.EcommerceEvent.Tax,
            shipping: 0,
            currency: "NZD",
            coupon: orderConfirmationData.Coupon,
            items: ecommerceItems,
            payment_type: Cookies.get(LOCALSTORAGE_PAYMENT)
        }
    }
}


//GA4 - view-item
export const mapViewItemToGAEcommerceEventModel = (event: string, apiUrl: string, isDealerOrder: boolean, transactionTypeName: string, plateDesignItem: CurrentPlateDesign, meta: PlateCategoryMetaInfo) => {
    return GAService.getPlateDesignNameAndPlateStyleCode(apiUrl, plateDesignItem.frontPlate.plateDesignCode, plateDesignItem.frontPlate.textColor.id, plateDesignItem.backPlate.plateDesignCode, plateDesignItem.backPlate.textColor.id)
        .then(res => {
            let ecommerceItems: EcommerceItem[] = []
            let itemCategory2 = ''
            let price = 0

            if (res.Success) {
                switch (transactionTypeName) {
                    case "new":
                        itemCategory2 = "New Design";
                        price = meta.startingPrice.new;
                        break;
                    case "redesign":
                    case "upgrade":
                        itemCategory2 = "Redesign";
                        price = meta.startingPrice.redesign;
                        break;
                }
                ecommerceItems.push({
                    item_id: res.Data.plateStyleCode,
                    item_name: res.Data.plateDesignName,
                    affiliation: "",
                    coupon: "",
                    currency: "NZD",
                    discount: 0,
                    index: 0,
                    item_brand: "Kiwiplates",
                    item_category: res.Data.plateGroupSetName,
                    item_category2: itemCategory2,
                    item_category3: isDealerOrder ? "Dealer" : "Individual",
                    item_category4: res.Data.plateGroupName,
                    item_category5: "",
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: plateDesignItem.frontPlate.textColor.name,
                    location_id: "",
                    price: price ? price : 0,
                    quantity: 1
                })
                return {
                    ga4Data: {
                        event: event,
                        ecommerce: {
                            value: price,
                            currency: "NZD",
                            items: ecommerceItems
                        }
                    },
                    gaData:{
                        itemID: res.Data.plateStyleCode,
                        itemName:res.Data.plateDesignName,
                        variant:plateDesignItem.frontPlate.textColor.name,
                    }
                }
            }
        })
}

//GA4 - add-to-cart
export const mapCartItemToGAEcommerceEventModel = (event: string, apiUrl: string, isDealerOrder: boolean, plateDesignItem: CurrentPlateDesign, price: number | undefined, transactionType: TransactionType) => {
    return GAService.getPlateDesignNameAndPlateStyleCode(apiUrl, plateDesignItem.frontPlate.plateDesignCode, plateDesignItem.frontPlate.textColor.id, plateDesignItem.backPlate.plateDesignCode, plateDesignItem.backPlate.textColor.id)
        .then(res => {
            let ecommerceItems: EcommerceItem[] = []
            if (res.Success) {

                let itemCategory2 = ''
                switch ((transactionType.name.split(' ')[0]).toLowerCase()) {
                    case "new":
                        itemCategory2 = "New Design";
                        break;
                    case "redesign":
                    case "upgrade":
                        itemCategory2 = "Redesign";
                        break;
                }
                ecommerceItems.push({
                    item_id: res.Data.plateStyleCode,
                    item_name: res.Data.plateDesignName,
                    affiliation: "",
                    coupon: "",
                    currency: "NZD",
                    discount: 0,
                    index: 0,
                    item_brand: "Kiwiplates",
                    item_category: res.Data.plateGroupSetName,
                    item_category2: itemCategory2,
                    item_category3: isDealerOrder ? "Dealer" : "Individual",
                    item_category4: res.Data.plateGroupName,
                    item_category5: "",
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: plateDesignItem.frontPlate.textColor.name,
                    location_id: "",
                    price: price ? price : 0,
                    quantity: 1
                })
                return {
                    ga4Data: {
                        event: event,
                        ecommerce: {
                            value: price,
                            currency: "NZD",
                            items: ecommerceItems
                        }
                    },
                    gaData:{
                        itemID: res.Data.plateStyleCode,
                        itemName:res.Data.plateDesignName,
                        variant:plateDesignItem.frontPlate.textColor.name,
                    }
                }
            }
        })

}

//GA purchase
export const buildGTADataLayerPurchase = (orderConfirmationData: OrderConfirmationDataModel) => {
    const products = orderConfirmationData.Items.map(bl => {
        return {
            name: `${bl.PlateDesignName}/${bl.DualPlateDesignName}`,
            id: `${bl.PlateDesignId}-${bl.ForegroundColourId}-${bl.PlateSizeId}/${bl.DualPlateDesignId !== null ? bl.DualPlateDesignId : bl.PlateDesignId}-${bl.DualPlateForegroundColourId !== null ? bl.DualPlateForegroundColourId : bl.ForegroundColourId}-${bl.DualPlateSizeId !== null ? bl.DualPlateSizeId : bl.PlateSizeId}`,
            brand: bl.TransactionType,
            category: `${bl.PlateRangeName}/${bl.PlateSizeName}/${bl.DualPlateSizeName}`,
            price: bl.PriceGst + bl.PriceExGst,
            quantity: 1,
            dimension1: bl.Combination,
            dimension2: bl.TransactionType
        }
    });
    let totalOrderPrice = 0;
    let totalOrderGst = 0;
    orderConfirmationData.Items.forEach((item) => {
        totalOrderPrice += (item.PriceExGst + item.PriceGst);
        totalOrderGst += item.PriceGst;
    });
    const purchase = {
        actionField: {
            id: orderConfirmationData.Items[0].TransactionId,
            revenue: orderConfirmationData.LaybyDetails !== null ? orderConfirmationData.LaybyDetails.DepositAmount : totalOrderPrice,
            tax: totalOrderGst,
            coupon: orderConfirmationData.Coupon,
            checkout_option: orderConfirmationData.LaybyDetails !== null ? orderConfirmationData.LaybyDetails.DaysInPeriod : "Pay in Full"
        },
        products: products
    };
    return {
        event: "purchase",
        ecommerce: {
            purchase: purchase
        }
    };
}

//GA4 - cc/a2a payment
export const mapPurchaseModelToGAEcommerceEventModel = (event: string, isDealerOrder: boolean, purchaseModel: any) => {
    let ecommerceItems: EcommerceItem[] = []

    purchaseModel.Items.map((item: any, index: number) => {
        let itemCategory2 = ''
        switch ((item.transactionType.split(' ')[0]).toLowerCase()) {
            case "new":
                itemCategory2 = "New Design";
                break;
            case "redesign":
            case "upgrade":
                itemCategory2 = "Redesign";
                break;
        }
        ecommerceItems.push({
            item_id: item.plateStyleCode,
            item_name: item.plateDesignName,
            affiliation: "",
            coupon: item.discount && purchaseModel.actionField.coupon ? purchaseModel.actionField.coupon : "",
            currency: "NZD",
            discount: item.discount ? item.discount : 0,
            index: index,
            item_brand: "Kiwiplates",
            item_category: item.plateGroupSetName,
            item_category2: itemCategory2,
            item_category3: isDealerOrder ? "Dealer" : "Individual",
            item_category4: item.plateGroupName,
            item_category5: "",
            item_list_id: "",
            item_list_name: "",
            item_variant: item.colorName,
            location_id: "",
            price: item.price,
            quantity: 1
        })
    })
    return {
        event: event,
        ecommerce: {
            transaction_id: purchaseModel.actionField.id,
            affiliation: '',
            value: purchaseModel.actionField.revenue,
            tax: purchaseModel.actionField.tax,
            shipping: 0,
            currency: "NZD",
            coupon: purchaseModel.actionField.coupon,
            items: ecommerceItems,
            payment_type: Cookies.get(LOCALSTORAGE_PAYMENT)
        }
    }
}